import React, { Component } from 'react';
import styled from 'styled-components'

import {Section, Title, Subtitle, VerticalBlock, CTA, LearnMore} from './Components'

const FeatureList = styled.ol`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size:16pt;
  color: #333;
`

const ListItem = styled.li`
  padding: 10px;
`

const MoreInfo = styled.a`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 10pt;
  margin-top: 10px;
  color: #888;
  text-decoration: none;
  display: block;
`

class App extends Component {
  render() {
    return (
      <div>
      <Section>
        <VerticalBlock center maxWidth={600}>
          <Title style={{textAlign: "center"}}>Genuine one on one connections with fans.</Title>
          <Subtitle>Here are some reasons we think Squad is great for creators:</Subtitle>
          <FeatureList>
            <ListItem>
              Notifications for all your new videos.
              <MoreInfo>All fans get a notification whenever you post a new video. No algorithms, no magic.</MoreInfo>
            </ListItem>
            <ListItem>
              Safe and Secure.
              <MoreInfo>Calls are made over the internet through the app — not using your phone number. Your contact info is never shared.</MoreInfo>
            </ListItem>
            <ListItem>
              Works Internationally.
              <MoreInfo>Calling fans in other countries works exactly the same as calling fans in your own country.</MoreInfo>
            </ListItem>
            <ListItem>
              Free for you. Free for your fans.
              <MoreInfo>Making people happy is what we do.</MoreInfo>
            </ListItem>
          </FeatureList>
          <Subtitle>We'd love to tell you more and answer any questions you might have!</Subtitle>
          <CTA width={240} style={{marginTop: 30}} href="mailto:creators@joyful.cc">Email us at creators@joyful.cc</CTA>
          <LearnMore href="/squad">Make sure to check out the main Squad page if you haven't already ></LearnMore>
        </VerticalBlock>
      </Section>
      </div>
    );
  }
}

export default App;
