export const PITCHES = {
  default: {
    hero: {
      title: "Phone calls with your favorite creators.",
      subtitle: "Squad is a free app that lets creators safely call fans and have genuine one on one interactions. It randomly chooses fans for creators to call so everyone has a fair chance."
    }
  },
  getrewarded_biggest_fan: {
    hero: {
      title: "Get rewarded for turning on post notifications.",
      subtitle: "Squad is a free app that lets creators safely call their biggest fans and have genuine one on one interactions. It randomly chooses fans for creators to call so everyone has a fair chance."
    }
  },
  getrewarded_direct: {
    hero: {
      title: "Get rewarded for turning on post notifications.",
      subtitle: "Squad is a free app that lets creators call fans who have post notifications turned on. It randomly chooses fans for creators to call so everyone has a fair chance."
    }
  },
}


export const CREATOR_MAPPING = {
  "jc": {
    "name": "JC Caylen",
    "twitter": ["jccaylen"],
    "ctaText": "Hmmm, it looks like JC Caylen isn’t on Squad yet. Let him know you want him to join!"
  },
  "kian": {
    "name": "Kian Lawley",
    "twitter": ["kianlawley"],
    "ctaText": "Hmmm, it looks like Kian Lawley isn’t on Squad yet. Let him know you want him to join!"
  },
  "merrelltwins": {
    "name": "Merrell Twins",
    "cta": "Tweet to the Merrell Twins",
    "twitter": ["merrelltwins", "vanessamerrell", "veronicamerrell", "thepaulmerrell"],
    "ctaText": "Hmmm, it looks like the Merrell Twins aren't on Squad yet. Let them know you want them to join!"
  },
  "franny": {
    "name": "Franny Arrieta",
    "twitter": ["arifranny"],
    "ctaText": "Hmmm, it looks like Franny Arrieta isn’t on Squad yet. Let her know you want her to join!"
  },
  "wassabi": {
    "name": "Alex Wassabi",
    "twitter": ["AlexWassabi"],
    "ctaText": "Hmmm, it looks like Alex Wassabi isn’t on Squad yet. Let him know you want him to join!"
  },
  "corey": {
    "name": "Corey La Barrie",
    "twitter": ["coreylabarrie"],
    "ctaText": "Hmmm, it looks like Corey La Barrie isn’t on Squad yet. Let him know you want him to join!"
  },
  "bobby": {
    "name": "Bobby Mares",
    "twitter": ["ItsBobbyMares"],
    "ctaText": "Hmmm, it looks like Bobby Mares isn’t on Squad yet. Let him know you want him to join!"
  },
  "dom": {
    "name": "Dominic DeAngelis",
    "twitter": ["DOMtheB0MB"],
    "ctaText": "Hmmm, it looks like Dominic DeAngelis isn’t on Squad yet. Let him know you want him to join!"
  },
  "lazyron": {
    "name": "Aaron Burriss",
    "twitter": ["lazyronstudios"],
    "ctaText": "Hmmm, it looks like Aaron Burriss isn’t on Squad yet. Let him know you want him to join!"
  },
  "kianandjc": {
    "name": "Kian and JC",
    "twitter": ["kianlawley", "jccaylen"],
    "ctaText": "Hmmm, it looks like Kian and JC aren't on Squad yet. Let them know you want them to join!",
    "pitch": PITCHES.getrewarded_direct,
  }
}
