import React from 'react';
import styled, { css } from 'styled-components'

export const Section = styled.div`
  padding: 50px;
  display:flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.bg || "transparent"};
  overflow: hidden;

  @media (max-width: 600px) {
    flex-direction: ${props => props.reverseOnMobile ? "column-reverse" : "column"};
    padding: 20px;
  }
`

export const VerticalBlock = styled.div`
  max-width: ${props => props.maxWidth ? props.maxWidth + "px" : "auto"};
  margin: 0px 50px;
  display: flex;
  flex-direction: column;
  ${props => props.center && css`align-items: center;`}

  @media (max-width: 600px) {
    margin: 0;
    margin-bottom: 50px;
  }
`

export const Title = styled.h1`
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size:36pt;

  @media (max-width: 600px) {
    font-size:24pt;
    margin-top: 0;
    text-align: center;
  }
`

export const Subtitle = styled.h2`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size:14pt;

  @media (max-width: 600px) {
    font-size:12pt;
    text-align: center;
  }
`

export const CTA = styled.a`
  display: block;
  padding: 20px;
  width: ${props => props.width || 200}px;
  background: #006989;
  text-align: center;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-size:12pt;
  text-decoration: none;

  @media (max-width: 600px) {
    margin: 0px auto;
  }
`

export const LearnMore = styled.a`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 10pt;
  margin-top: 30px;
  color: #888;
  text-decoration: none;
  display: block;

  @media (max-width: 600px) {
    text-align: center;
    ${props => props.reverseOnMobile && css`margin-top: 0px; margin-bottom: 30px;`};
  }
`

const Spacer = styled.div`
  flex: 1;
`

const Logo = styled.img`
  height: 40px;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`

const ForCreators = styled.a`
  justify-self: flex-end;
  color: #333;
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-size: 12pt;
`

const FooterItem = styled.a`
  font-family: 'Lato', sans-serif;
  font-size: 10pt;
  color: #888;
  text-decoration: none;

  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
`

const Separator = styled.a`
  font-family: 'Lato', sans-serif;
  font-size: 10pt;
  color: #888;
  margin: 0px 10px;

  @media (max-width: 600px) {
    display: none;
  }
`

export const Header = () => (
  <Section>
    <a href="/"><Logo src="/logo.svg"/></a>
    <Spacer/>
    <ForCreators href="/squad/creators">For Creators ></ForCreators>
  </Section>
)

export const Footer = () => (
  <Section bg="#efefef">
    <a href="/"><Logo src="/logo.svg"/></a>
    <Spacer/>
    <FooterItem href="mailto:hello@joyful.cc">Contact Us</FooterItem>
    <Separator>|</Separator>
    <FooterItem href="/squad/creators">For Creators</FooterItem>
  </Section>
)
