import React, { Component } from 'react';
import styled from 'styled-components'

import {Section, Title, Subtitle, VerticalBlock, CTA, LearnMore} from './Components'

import {PITCHES, CREATOR_MAPPING} from './CreatorInfo'

const DevicePortrait = styled.img`
  height: 600px;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom + "px" : "auto"};

  @media (max-width: 600px) {
    margin-bottom: ${props => props.marginBottomMobile ? props.marginBottomMobile + "px" : "auto"};;
  }
`

const RelativeDeviceWrapper = styled.div`
  position: relative;

  @media (max-width: 600px) {
    overflow: hidden;
    max-height: 200px;
    margin-bottom: -20px;
  }
`

const DeviceMockupCreatorName = styled.a`
  font-family: 'SFPro';
  position: absolute;
  left: 0;
  top: 85px;
  width: 100%;
  color: #fff;
  font-size: 26px;
  text-align: center;
  letter-spacing: 0.2px;
  user-select: none;
`

const DeviceMockupNotification = styled.a`
  font-family: 'Roboto', sans-serif;
  position: absolute;
  left: 26px;
  top: 108.5px;
  width: 100%;
  color: #212121;
  font-size: 8.55px;
  letter-spacing: 0.2px;
  user-select: none;
`

const SecondaryCTA = styled.a`
  text-decoration: underline;
  font-family: 'Lato', sans-serif;
  color: #aaa;
  font-weight: 400;
  font-size: 10pt;
  margin-left: 20px;
`

const CTAWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    margin-top: 20px;
    flex-direction: ${props => props.reverseOnMobile ? "column-reverse" : "column"};
  }
`

const CreatorCTA = ({creatorID}) => {
  const creatorSpec = CREATOR_MAPPING[creatorID];
  const creatorName = creatorSpec.name
  const twitterAccounts = creatorSpec.twitter.map((i) => `@${i}`).join(" ");

  const cta = creatorSpec.cta || "Tweet to " + creatorName

  return (
    <CTAWrapper reverseOnMobile>
      <CTA target="_blank" rel="noopener noreferrer" href={"https://twitter.com/intent/tweet?text=. " + twitterAccounts + " Join Squad to call fans! https://joyful.cc/squad/" + creatorID + " @joinsquadapp&hashtags=getsquad"}>
        {cta}
      </CTA>
      <LearnMore reverseOnMobile>{creatorSpec.ctaText}</LearnMore>
    </CTAWrapper>
  );
}

const GenericCTA = () => (
  <CTAWrapper>
    <CTA href="/squad/waitlist">Get Squad!</CTA>
  </CTAWrapper>
)

const DynamicCTA = (props) => props.creatorID == null || CREATOR_MAPPING[props.creatorID] == null ? <GenericCTA {...props} /> : <CreatorCTA {...props}/>

const randomProperty = (obj) => {
    var keys = Object.keys(obj)
    return obj[keys[ keys.length * Math.random() << 0]];
};


const SquadLanding = ({match}) => {
  const creatorID = match.params.id;
  const creatorSpec = creatorID == null ? null : CREATOR_MAPPING[creatorID]
  const creatorName = creatorSpec == null ? randomProperty(CREATOR_MAPPING).name : creatorSpec.name

  const pitchConfig = (creatorSpec || {}).pitch || PITCHES.default;
  return (
    <div>
    <Section>
      <VerticalBlock maxWidth={400}>
        <Title>{pitchConfig.hero.title}</Title>
        <Subtitle>{pitchConfig.hero.subtitle}</Subtitle>
        <DynamicCTA creatorID={creatorID}/>
      </VerticalBlock>
      <RelativeDeviceWrapper>
        <DevicePortrait src="/iphone_call_no_text.png" style={{marginLeft: 5, marginRight: 5}} />
        <DeviceMockupCreatorName>{creatorName}</DeviceMockupCreatorName>
      </RelativeDeviceWrapper>
    </Section>
    <Section bg="#f6e6e9" reverseOnMobile>
      <RelativeDeviceWrapper>
        <DevicePortrait src="/nexus2.png" />
        <DeviceMockupNotification>{creatorName} just posted a video: Calling fans on Squad!</DeviceMockupNotification>
      </RelativeDeviceWrapper>
      <VerticalBlock maxWidth={400}>
        <Title>Make sure you never miss a video.</Title>
        <Subtitle>Squad notifies you every time your favorite creators post on YouTube — No algorithms, No magic.</Subtitle>
      </VerticalBlock>
    </Section>
    <Section>
      <VerticalBlock>
        <Title>Free.</Title>
        <Subtitle>We love making people happy — that's enough for us.</Subtitle>
        <GenericCTA/>
        <LearnMore href="mailto:hello@joyful.cc">Questions or Feedback? Send us an email ></LearnMore>
      </VerticalBlock>
      <DevicePortrait src="/iphone7.png" marginBottom={-500} marginBottomMobile={-400} />
    </Section>
    </div>
  );
}

// <LearnMore href="/">Learn more about Joyful Labs ></LearnMore>

export default SquadLanding;
