import React, { Component } from 'react';
import styled from 'styled-components'
import {Section, Title, Subtitle, VerticalBlock} from './Components'

const FormFrame = styled.iframe`
  width: 640px;
  height: 1200px;
  border: 0;

  @media (max-width: 640px) {
    width: 100%;
  }
`

class App extends Component {
  render() {
    return (
      <div>
      <Section>
        <VerticalBlock center maxWidth={640}>
          <Title>Join the waitlist!</Title>
          <Subtitle>We're rolling out to more fans in the order that they sign up. Every time a new creator joins, we're letting more fans in so make sure to let your favorite creators know about Squad!</Subtitle>
          <Subtitle/>
          <FormFrame src="https://docs.google.com/forms/d/e/1FAIpQLScKv19UzaytJ_0_02FgVil9JcsYD1ePPZe3gyc3vsauyGHXoA/viewform?embedded=true" marginheight="0" marginwidth="0" title="waitlistform">Loading...</FormFrame>
        </VerticalBlock>
      </Section>
      </div>
    );
  }
}

export default App;
