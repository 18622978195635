import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import Squad from './Squad';
import Waitlist from './Waitlist';
import Creators from './Creators';
import {Header, Footer} from './Components';

function AppRouter() {
  return (
    <Router>
      <Header/>
      <Switch>
        <Route path="/squad/" exact component={Squad} />
        <Route path="/squad/waitlist" component={Waitlist} />
        <Route path="/squad/creators" component={Creators} />
        <Route path="/squad/:id" exact component={Squad} />
        <Redirect to="/squad"/>
      </Switch>
      <Footer/>
    </Router>
  );
}

export default AppRouter;
